$main: var(--primary-bg-color, #b71c1c);
$main-fg-color: var(--primary-fg-color, white);

$link-color: var(--link-color, #751212);
$link-hover-color: var(--link-hover-color, #ca2121);

$white: white;
$red: #b71c1c;

$gray-50: whitesmoke;
$gray-100: lightgrey;
$gray-200: #a9a9a9;
