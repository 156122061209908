@use '@angular/material' as mat;
@import '../../../node_modules/@angular/material/theming';
@import './colors';
@import './animations';
@import './backgrounds';
@import './fonts';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$primary: mat.define-palette(mat.$red-palette, 900, 100, 900);
$accent:  mat.define-palette(mat.$deep-orange-palette, 900, 100, 900);
$theme: mat.define-light-theme($primary, $accent);
@include mat.all-legacy-component-themes($theme);

// LOGIN FORM
.login-title {
  font-size: 64px;
  color: $main;
  font-weight: bold;
  text-shadow: 0 0 6px;
  text-align: center;
  -webkit-text-stroke: 0.5px black;
}
// LOGIN FORM END

fieldset {
  border: none;
  padding: 0;
}

input, select {
  border: 1px solid #666;
  border-radius: 0px;
  background: white;
  padding-top: 3px;
  padding-bottom: 3px;
}

// SIDENAV
.sidenav-main-title {
  @extend .primary;
  height: 32px;
}
.sidenav-main-title.mat-toolbar {
  background-color: var(--primary-bg-color, #ccc) !important;
  color: var(--primary-fg-color, #fff) !important;
}

.sidenav-main-content {
  @extend .bg-solid-gray-200;
  display: flex !important;
}

.sidenav-main-content > :not(router-outlet) {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sidenav-container {
  height: calc(100% - 32px);
}

.sidenav-notifications {
  @extend .bg-solid-gray-50;
  width: 240px;
  border-left: 1px solid #000000 !important;
}

.sidenav {
  @extend .bg-messaging;
  @extend .bg-solid-gray-50;
  width: 240px;
  border-right: 1px solid #410a0a;
}

.sidenav-menu {
  background-color: white;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: normal;
  font-size: 14px;
  transition: margin 0.3s ease-in, border-radius 0.5s ease-in-out;
}

.sidenav-menu:hover {
  font-weight: bold;
}

.sidenav-menu mat-icon {
  width: 12px;
  height: 12px;
  font-size: 12px;
}

.menu-item-active {
  box-shadow: 0 0 3px 0 $main;
  border-color: $main;
  margin-bottom: 5px;
  border-bottom: 1px solid black;
  margin-top: 5px;
  border-top: 1px solid black;
  font-size: 18px;
  font-weight: bold;
}
// SIDENAV END

// GENERAL
label {
  white-space: nowrap !important;
}

.vertical-form {
  @extend .vbox;
  @extend .vbox-space;
  @extend .font-size-12;
  @extend .text-center;
}

.login-form {
  @extend .bg-roundabouts;
}

.active {
  color: $main !important;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.full-height {
  height: 100%;
}

.align-top {
  vertical-align: top;
}

.stick-top {
  position: sticky;
  top: 0;
  z-index: 100;
}

.stick-bottom {
  position: sticky;
  bottom: 0;
  z-index: 101;
}

.mat-snack-bar-container{
  border-radius: 0px !important;
  border: 1px solid black !important;
}

.round-borders {
  border-radius: 3px;
}

.mat-simple-snackbar-action {
  color: whitesmoke !important;
}

.mat-raised-button {
  line-height: 16px !important;
  border-radius: 0px !important;
  padding: 3px !important;
  min-width: 0px !important;
}

.supressed-button {
  line-height: 16px !important;
  border-radius: 0px !important;
  padding: 3px !important;
  min-width: 0px !important;
  border-top: 1px solid black !important;
  border-left: 1px solid black !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.41) !important;
  border-right: 1px solid rgba(255, 255, 255, 0.41) !important;
  box-shadow: inset 0px 0 3px 0px black;
  cursor: pointer;
  font-weight: bold;
}

.mat-raised-button .mat-icon, .supressed-button .mat-icon{
  width: 12px !important;
  height: 12px !important;
  font-size: 12px !important;
  margin: auto;
}

.mono, .mono * {
  @extend .mono-font;
  font-size: 12px;
}

.fill-space {
  flex: 1 1 auto;
}

mat-header-cell input, mat-header-cell select {
  width: 90%;
  max-width: 200px;
}

.padded {
  padding: 10px;
}

.lightly-padded {
  padding: 5px;
}

.lightly-padded-left {
  padding-left: 5px;
}

.lightly-padded-right {
  padding-right: 5px;
}

.mat-checkbox-label {
  padding-left: 0px !important;
}

mat-radio-button {
  padding: 0 5px 0 5px !important;
}

.breadcrumb {
  @extend .mono;
  font-size: x-small;
  padding-left: 5px;
}

.breadcrumb * {
  color: $main-fg-color !important;
  text-decoration: none !important;
}

a {
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;
}

a:hover {
  color: $link-hover-color;
  text-decoration: underline;
}

.fr-view table td, .fr-view table th, .fr-element table td, .fr-element table th {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.fr-view table, .fr-element table {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-spacing: 0px;
}

mat-tree-node {
  min-height: 16px !important;
}

.mat-checkbox-label {
  padding-left: 10px;
}

mat-footer-row, mat-row {
  min-height: 20px !important;
}

mat-cell {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block !important;
}

.CRITICAL, .CRITICAL * {
  color: white !important;
  background: $red !important;
  fill: $red !important;
}

.MAJOR, .MAJOR * {
  color: white !important;
  background: darkorange !important;
  fill: darkorange !important;
}

.WARNING, .WARNING * {
  color: white !important;
  background: orange !important;
  fill: orange !important;
}

.MINOR, .MINOR * {
  color: black !important;
  background: yellow !important;
  fill: yellow !important;
}

.INFO, .INFO * {
  color: black !important;
  background: lightblue !important;
  fill: lightblue !important;
}

.SUCCESS, .SUCCESS *, .CLEARED, .CLEARED * {
  color: white !important;
  background: green !important;
  fill: green !important;
}

// DATA TABLE
.dataTable {
  background: white;
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.dataTable th {
  @extend .mono-font;
  font-size: 12px;
  font-weight: bold !important;
  border: 1px solid black;
  padding: 2px;
  text-transform: uppercase;
}

.dataTable td {
  @extend .mono-font;
  font-size: 12px;
  border: 1px solid black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dataTable tr:nth-child(even) {
  background: #CCC
}

.alarmDashboardContainer {
  max-height: calc(100vh - 32px);
  overflow: auto;
}

.dataTable tbody :not(mat-icon):not(th) {
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;
}

.cellLink:hover {
  background: rgba(0, 0, 0, 0.36) !important;
}

.dataTable tbody tr:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}

.dataTable th select, .dataTable td select, .dataTable td input {
  width: 100%;
}

textarea {
  @extend .mono;
}

.bold {
  font-weight: bold;
}

.em {
  font-style: italic;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-error {
  color: $red;
}

.font-size-10 {
  font-size: 10px;
  color: gray;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px !important;
}

.center {
  margin: auto;
  width: 50%;
}

.smChart {
  //width: 100% !important;
  margin: 0 auto;
  display: block;
  border: 1px solid black;
}

.picklist {
  background: white;
  border: 1px solid #000000;
  display: inline-block;
  padding: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.icon-small {
  width: 12px !important;
  height: 12px !important;
  font-size: 12px;
}

* {
  font-family: Roboto;
}

ace-editor * {
  font-family: monospace !important;
}

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
}

.warn {
  background-color: $red;
}

.info {
  background-color: green;
}

.flex {
  display: flex;
}

.col {
  @extend .flex;
  flex-flow: column;
}

.row {
  @extend .flex;
  flex-flow: row;
}

.flex-content {
  flex: 1 1 auto;
}

//.box .row.header {
//  flex: 0 1 auto;
//}
//
//.box .row.content {
//  flex: 1 1 auto;
//}
//
//.box .row.footer {
//  flex: 0 1 40px;
//}

.vbox {
  display: inline-flex;
  flex-direction: column;
}

th .vbox {
  display: flex;
}

.vbox-full {
  display: flex;
  flex-direction: column;
}

.vbox-space > * {
  margin-bottom: 10px !important;
}

.hbox {
  display: flex;
  flex-wrap: wrap;
}

.hbox-vert-space > * {
  margin-bottom: 5px !important;
}

.hbox-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

button {
  white-space: nowrap;
}

.hbox > * {
  margin-right: 3px !important;
}

.hbox-nospace {
  display: flex;
}

.no-margin-right * {
  margin-right: 0px !important;
}

.simpleTable {
  border-collapse: collapse;
}

.simpleTable th {
  font-size: 12px;
  font-weight: bold;
  color: black;
}

.simpleTable td {
  color: black;
  font-size: 12px;
}

.basicTable {
  background: white;
  border: 1px solid black;
  border-collapse: collapse;
  box-shadow:0px 0px 3px 0px rgba(0, 0, 0, 0.5);
}

.vtable tbody th {
  @extend .text-right;
}

.vtable tbody td > input[type=checkbox] {
  width: auto !important;
}

.basicTable th[colspan='100'] {
  background: $main !important;
  color: $main-fg-color !important;
}


.basicTable th {
  border: 1px solid black;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  padding: 3px;
}

.basicTable td {
  border: 1px solid black;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 3px;
}

.border-bottom-double {
  border-bottom: double !important;
}

.border-top-double {
  border-top: double !important;
}

pre {
  padding: 3px;
  margin: 0px;
}

.basicTable tr:nth-child(even) {
  background: lightgray;
}

.basicTable td > * {
  box-sizing: border-box;
  width: 100%;
}

.basicTable td > input[type="checkbox"] {
  width: auto;
}

.mat-tab-label-container {
  margin-top: 5px;
}

.mat-tab-label {
  height: 24px !important;
  border: 1px solid black;
  border-bottom: 0px;
  opacity: 1 !important;
  transition: border-top-right-radius 0.5s ease-in-out, background-color 0.5s ease-in-out;
  transform: translateY(1px) !important;
  background: #efefef;
  min-width: 10px !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.mat-ink-bar {
  background: $main !important;
}

.mat-tab-label-active {
  background: white;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.41);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transform: translateY(0px) !important;
}

.mat-tab-header-pagination-controls-enabled, .mat-tab-header-pagination {
  border: 1px solid black;
}

.mat-progress-bar-buffer {
  background-color: #eee !important;
}

.mat-tab-header {
  border-bottom: 1px solid black;
}

.mat-tab-label-content {
  color: black !important;
}

.inset-shadow {
  box-shadow: inset 0px 0 3px 0px rgba(0, 0, 0, 0.5);
}

mat-sidenav-content {
  box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  background: darkgray;
}

mat-toolbar {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid black;
}

mat-paginator {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
}

.basicTable tfoot mat-paginator {
  box-shadow: none !important;
}

.basicTable tfoot mat-paginator * .mat-paginator-container {
  border: none !important;
}

.no-shadow {
  box-shadow: none;
}

.boxwrapper-no-shadow {
  background: white;
  border: 1px solid black;
}

.boxwrapper {
  @extend .boxwrapper-no-shadow;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}

.dark-background {
  background: dimgray;
  box-shadow: inset 0 0 3px 0 black;
}

.white-background {
  background: white;
}

.border-top {
  border-top: 1px solid black;
}

.border-right {
  border-right: 1px solid black;
}

.border-left {
  border-left: 1px solid black;
}

.border-bottom {
  border-bottom: 1px solid black;
}

.mat-paginator-container {
  min-height: 32px !important;
  padding: 0px !important;
  flex-wrap: nowrap !important;
  height: 32px;
  border-bottom: 1px solid black;
  color: black;
}

.primary {
  background-color: $main;
  color: $main-fg-color;
}

.shortcut {
  cursor: pointer;
}

.box {
  transition: margin 0.3s ease-in;
}

.active-box {
  box-shadow: 0 0 3px 0 $main !important;
  border-color: $main !important;
  font-size: 18px !important;
  font-weight: bold !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.shortcut:hover {
  background-color: rgba(207, 207, 207, 0.1);
}

.menu-item-root {
  background: $main;
  color: $main-fg-color;
}

.menu-expanded {
}

.menu-item-parent {
  background: rgb(210, 210, 210)
}

.loadingSpinner ::ng-deep circle {
  stroke: white !important;
}

.gridCell {
  cursor: pointer;
}

.gridDataCell {
  text-align: center;
}

.mat-raised-button {
  border: 1px solid black !important;
}

mat-tab-header {
  background: white;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 32px !important;
}

.button-bar {
  @extend .bg-solid-gray-100;
  border-bottom: 1px solid black;
}

.button-bar > * {
  margin-top: auto;
  margin-bottom: auto;
}

.button-bar button {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-auto-vert {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-auto-hor {
  margin-left: auto;
  margin-right: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}
.grid-item {
}

.chart {

}

.micro-checkbox {
  margin: auto;
}

mat-tree {
  display: inline-block !important;
}

.disabled {
  opacity: 0.5;
}

.dateTimeHeader {
  max-width: 173px;
}

.userHeader {
  max-width: 150px;
}

.contactHeader {
  max-width: 150px;
}

.guuidHeader {
  width: 230px;
}

.msisdnHeader {
  max-width: 100px;
}

.margin-auto {
  margin: auto;
}

.fr-toolbar.fr-top {
  border-radius: 0 !important;
  border-width: 1px 1px 0px 1px !important;
}

.second-toolbar {
  color: black !important;
  border-radius: 0 !important;
  border-width: 0px 1px 1px 1px !important;
}

.fr-toolbar, .fr-box.fr-basic .fr-wrapper, .second-toolbar {
  border: 1px solid black !important;
}

:disabled {
  color: #333 !important;
}

.mat-radio-label-content {
  padding-left: 4px !important;
}

.mat-radio-container,.mat-radio-outer-circle,.mat-radio-inner-circle {
  width: 12px !important;
  height: 12px !important;
  border-color: $main;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $main;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $main;
}

.mat-dialog-container {
  padding: 10px !important;
  border: 1px solid black !important;
  overflow: hidden !important;
}

.mat-dialog-title {
  font-size: 14px !important;
  margin: 0px !important;
}

.mat-dialog-actions {
  padding-top: 5px !important;
  margin: auto !important;
  min-height: auto !important;
}

.micro-select-combo-nominwidth {
  min-width: auto !important;
}

.micro-select-combo.ng-select {
  width: 100%;
  min-width: 120px;
  //font-weight: normal;
  font-size: 1.1em;
}

.micro-select-combo.ng-select .ng-select-container  {
  color: #000;
  height: 24px;
  min-height: 24px;
  border-radius: 0;
  border: 1px solid #666;
}

.micro-select-combo.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border: 2px solid #333;
  border-radius: 4px;
  box-shadow: none;
}

.micro-select-combo.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0px;
  padding-left: 1px;
}

.micro-select-combo.ng-select .ng-select-container .ng-value-container {
  padding-left: 3px;
}

.micro-select-combo.ng-select .ng-clear-wrapper {
  font-weight: normal;
  padding-left: 5px;
}

.micro-select-combo.ng-dropdown-panel {
  width: auto !important;
  background-color: #eee;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #aaa;
}
.micro-select-combo.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 260px;
}

.micro-select-combo.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  text-align: left;
  background-color: #eee;
  padding: 4px 5px;
  font-weight: normal;
  font-size: 0.9em;
}

.micro-select-combo.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:before{
  content: "\0020";
  display: inline-block;
  width: 0.9em;
}

.micro-select-combo.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected:before{
  content: "\2713";
}

.micro-select-combo.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #1f68dc;
  color: #fff;
  border-radius: 3px;
}

.micro-select-combo.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label,
.micro-select-combo.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked .ng-option-label {
  font-weight: normal;
}


.micro-select-combo.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.micro-select-combo.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: bold;
}
