@import './colors';

$assets_bg_asanoha: "/assets/bg/asanoha.png";
$assets_bg_bubble: "/assets/bg/bubble.png";
$assets_bg_messaging: "/assets/bg/messaging.png";
$assets_bg_swirl: "/assets/bg/swirl.png";
$assets_bg_hex: "/assets/bg/hex.png";
$assets_bg_norwegian: "/assets/bg/norwegian.png";
$assets_bg_gplaypattern_2X: "/assets/bg/gplaypattern_@2X.png";
$assets_bg_light_wool_2X: "/assets/bg/light_wool_@2X.png";
$assets_bg_roundabouts: "/assets/bg/circles-and-roundabouts.png";
$assets_bg_strange_bullseyes_2X: "/assets/bg/strange_bullseyes_@2X.png";

.bg-solid-white {
  background-color: $white;
}

.bg-solid-gray-50 {
  background-color: $gray-50;
}

.bg-solid-gray-100 {
  background-color: $gray-100;
}

.bg-solid-gray-200 {
  background-color: $gray-200;
}

.bg-double-bubble {
  background-image: url($assets_bg_asanoha) !important;
}

.bg-roundabouts {
  background-image: url($assets_bg_roundabouts) !important;
  background-position: center !important;
}

.bg-strange_bullseyes_2X {
  background-image: url($assets_bg_strange_bullseyes_2X) !important;
  background-position: center !important;
}


.bg-messaging {
  background-image: url($assets_bg_messaging) !important;
}

.bg-hex {
  background-image: url($assets_bg_hex) !important;
}

.bg-swirl {
  background-image: url($assets_bg_swirl) !important;
}

.bg-asanoha {
  background-image: url($assets_bg_asanoha) !important;
}

.bg-norwegian {
  background-image: url($assets_bg_norwegian) !important;
}

.bg-gplaypattern_2X {
  background-image: url($assets_bg_gplaypattern_2X) !important;
}

.bg-light_wool_2X {
  background-image: url($assets_bg_light_wool_2X) !important;
}
